import React from 'react'
import { graphql } from 'gatsby'
//
import Layout from '~components/layout'
import SEO from '~components/seo'
import { locales } from '~storybook/i18n'
import LanguageSelector from '~storybook/language-selector'

const Page = ({ data, location, pageContext }) => {
  const { language, langSlugs, metatags } = pageContext
  const node = data?.drupal?.node
  const group = data?.drupal?.group

  if (!group) {
    return null
  }

  const entityTranslations = node?.entityTranslations || []

  const logo = {
    url: group?.fieldLogo?.entity?.thumbnail?.url,
    width: group?.fieldLogo?.entity?.thumbnail?.width,
    height: group?.fieldLogo?.entity?.thumbnail?.height
  }

  const nodeTranslations = {}

  // eslint-disable-next-line no-unused-expressions
  entityTranslations?.forEach((entity) => {
    nodeTranslations[entity?.entityLanguage?.id] = entity?.entityLabel
  })

  const options = Object.keys(langSlugs)
    .filter((key) => Object.prototype.hasOwnProperty.call(locales, key))
    .map((key) => {
      let subtitle = nodeTranslations?.[key]

      if (location?.pathname === '/') {
        //subtitle = group?.fieldSiteName
      } else if (location?.pathname === '/' && !logo?.url) {
        subtitle = null
      }

      return {
        id: `lang-${key}`,
        url: langSlugs?.[key] || `/${key}`,
        title: locales[key].transName,
        subtitle
      }
    })
  
  const fieldLanguageSplashLogo = group?.fieldLanguageSplashLogo
  const fieldLanguageSplashSitetitle = group?.fieldLanguageSplashSitetitle

  const props = {
    options,
    fieldLanguageSplashLogo,
    fieldLanguageSplashSitetitle
  }

  if (logo && logo.url) {
    props.logo = logo
  } else {
    props.title = group?.fieldSiteName
  }

  return (
    <Layout language={language.toLowerCase()} disableLangSwitcher noFooter noHeader>
      <SEO
        title={group?.fieldSiteName || node?.title}
        metatags={metatags}
        globalImage={group?.fieldMetaSiteImage?.entity?.thumbnail?.url}
      />
      <LanguageSelector {...props} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($gid: String!, $nid: String!, $language: Drupal_LanguageId!) {
    drupal {
      node: nodeById(id: $nid, language: $language) {
        title
        path {
          alias
        }
        entityId
        langcode {
          value
        }
        entityTranslations {
          ... on Drupal_Entity {
            entityLabel
            entityLanguage {
              id
            }
          }
        }
      }
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
    }
  }
`
